// // imageCompression.js
// export const compressImage = (
//   file,
//   maxWidth = 800,
//   maxHeight = 800,
//   quality = 0.7
// ) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);

//     reader.onload = (event) => {
//       const img = new Image();
//       img.src = event.target.result;

//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         const ctx = canvas.getContext("2d");

//         const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
//         canvas.width = img.width * ratio;
//         canvas.height = img.height * ratio;

//         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

//         canvas.toBlob(
//           (blob) => {
//             resolve(blob);
//           },
//           file.type,
//           quality
//         );
//       };

//       img.onerror = (error) => {
//         reject(error);
//       };
//     };

//     reader.onerror = (error) => {
//       reject(error);
//     };
//   });
// };

export const compressImage = (
  file,
  maxWidth = 800,
  maxHeight = 800,
  maxSizeInBytes = 2 * 1024 * 1024 // 2MB
) => {
  const initialQuality = 0.7; // Initial quality value
  let currentQuality = initialQuality;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        canvas.width = img.width * ratio;
        canvas.height = img.height * ratio;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const compressAndCheckSize = () => {
          canvas.toBlob(
            (blob) => {
              if (blob.size <= maxSizeInBytes || currentQuality <= 0) {
                // If the blob size is within the limit or quality is already at 0, resolve with the compressed file
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                });
                resolve(compressedFile);
              } else {
                // Decrease the quality and re-compress
                currentQuality -= 0.1; // Decrease quality by 0.1
                ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Redraw the image
                canvas.toBlob(compressAndCheckSize, file.type, currentQuality); // Compress with new quality
              }
            },
            file.type,
            currentQuality
          );
        };

        compressAndCheckSize();
      };

      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
