import axios from "axios";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { Card, ListGroup, Offcanvas } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
const ReviewOrders = () => {
  const [orders, setOrders] = useState([]);
  const [editOrder, setEditOrder] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [show, setShow] = useState(false);
  const [canvashow, setCanvashow] = useState(false);
  const [kariger, setKariger] = useState([]);
  const [workshop, setWorkshop] = useState([]);
  // const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isUrgent, setIsUrgent] = useState(null);
  const [selectedOrderID, setSelectedOrderID] = useState("");
  const [updateOrder, setUpdateOrder] = useState({
    size: "",
    quantity: "",
    tunch: "",
    weight: "",
    description: "",
  });
  const [task, setTask] = useState({
    order: "",
    workshop: "",
    kariger: "",
  });
  const inputHandler = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
    setUpdateOrder({
      ...updateOrder,
      [e.target.name]: e.target.value,
    });
  };

  const handlecanvaClose = () => setCanvashow(false);
  const handlecanvaShow = () => setCanvashow(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let token = localStorage.getItem("token");

  // const delItem = async (delId) => {
  //   console.log("delId", delId);
  //   if (confirm("Do you want to delete this?")) {
  //   const res = await axios.delete(`https://admin.ngjeweller.in:8000/delete/order/${delId}`, {
  //     headers: {
  //       "Authorization": 'Bearer ' + token,
  //       Accept: "application/json",
  //       'Content-Type': 'application/json'
  //     }
  //   });
  //   if (res.data.success) { toast.warn("Order deleted..."); }
  // }}
  const delItem = async (delId) => {
    // console.log("delId", delId);
    if (window.confirm("Do you want to delete this?")) {
      try {
        const res = await axios.delete(
          `https://admin.ngjeweller.in:8000/delete/order/${delId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.success) {
          toast.warn("Order deleted...");
          const updatedOrders = orders.filter((order) => order._id !== delId);
          setOrders(updatedOrders);
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        toast.error("Failed to delete order. Please try again later.");
      }
    }
  };
  const updateItem = async (id) => {
    try {
      setShow(true);
      // console.log("id", id);
      const res = await axios.get(
        `https://admin.ngjeweller.in:8000/getallorders/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res", res.data.data);
      setUpdateOrder(res.data.data[0]);
      setEditOrder(res.data.data);
      console.log("editOrder", editOrder);
    } catch (error) {
      console.log(error);
      alert("Network Error");
    }
  };
  useEffect(() => {
    console.log("editOrder1", editOrder);
  }, [editOrder]);

  const handleStatusChange = (event) => {
    setCurrentStatus(event.target.value);
  };
  const saveUpdate = async (id) => {
    try {
      const { kariger: karigerValue, workshop: workshopValue } =
        editOrder[0] || {};

      if (!task.kariger) {
        toast.warn("Please provide the Kariger to assign the order.");
        return;
      }
      if (!task.workshop) {
        toast.warn("Please provide the Workshop to assign the order.");
        return;
      }
      if ((karigerValue && task.kariger) || (workshopValue && task.workshop)) {
        toast.warn("Order is already assigned.");
        return;
      }

      const token = localStorage.getItem("token");
      const res = await axios.put(
        `https://admin.ngjeweller.in:8000/update/order/${id}`,
        task,
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setShow(false);
      if (res.data.success) {
        toast.success("Order updated successfully");
        const updatedOrders = orders.map((order) =>
          order._id === id ? res.data.data : order
        );
        setOrders(updatedOrders);
      } else {
      }
    } catch (error) {
      console.log(error);
      alert("Network Error");
    }
  };

  const UpdateDetails = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `https://admin.ngjeweller.in:8000/update/order/details/${id}`,
        updateOrder,
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setShow(false);
      if (res.data.success) {
        toast.success("Order details updated successfully");
        const updatedOrders = orders.map((order) =>
          order._id === editOrder._id ? res.data.data : order
        );
        setOrders(updatedOrders);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateStatus = async (id) => {
    try {
      console.log("id", id);
      const token = localStorage.getItem("token");
      const data = { statusIs: currentStatus };
      const res = await axios.put(
        `https://admin.ngjeweller.in:8000/update/task/${id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res", res.data);
      // setEditOrder(res.data.data);
      setShow(false);
      if (res.data.success) {
        toast.success("Order updated successfully");
        const updatedOrders = orders.map((order) =>
          order._id === editOrder._id ? res.data.data : order
        );
        setOrders(updatedOrders);
      }
    } catch (error) {
      console.log(error);
      alert("Network Error");
    }
  };

  useEffect(() => {
    try {
      const fetchOrders = async () => {
        await axios
          .get(`https://admin.ngjeweller.in:8000/getallorders`, {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res);
            setOrders(res.data.data);
          });
      };
      fetchOrders();
    } catch (error) {
      console.log(error);
      alert("Network Error");
    }
  }, []);
  console.log(orders);

  useEffect(() => {
    try {
      axios
        .get("https://admin.ngjeweller.in:8000/kariger/list", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setKariger(res.data.data);
        });
    } catch (error) {
      console.log(error);
      alert("Network Error");
    }
  }, []);
  console.log("kariger", kariger);
  useEffect(() => {
    try {
      axios
        .get("https://admin.ngjeweller.in:8000/workshop/list", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setWorkshop(res.data.data);
        });
    } catch (error) {
      console.log(error);
      alert("Network Error");
    }
  }, []);
  const resetAll = () => {
    setSelectedCategory("");
    setIsUrgent("");
    setSelectedOrderID("");
  };
  const getUniqueNames = (orders) => {
    const uniqueNamesSet = new Set();
    orders.forEach((order) => {
      uniqueNamesSet.add(order.createdBy.name);
    });
    return Array.from(uniqueNamesSet);
  };

  const handleOrderList = (filtered = false) => {
    // Check if there are orders
    if (orders.length === 0) {
      alert("No orders to export.");
      return;
    }

    // Define the data headers
    const headers = [
      "Order By",
      "Order ID",
      "Category",
      "Size",
      "Weight",
      "Status",
      "Workshop",
      "Kariger",
      "Ordered On",
    ];

    // Map over the orders to create the data array based on filters
    let data;
    let filename = "order_list";
    let filterNames = {};
    if (filtered) {
      filterNames = {
        jeweler: selectedOrderID,
        category: selectedCategory,
        status: isUrgent ? "urgent" : "",
      };
      data = orders
        .filter(
          (order) =>
            (!isUrgent || order.urgent === isUrgent) &&
            (!selectedOrderID ||
              order.createdBy.name.toLowerCase() ===
                selectedOrderID.toLowerCase()) &&
            (!selectedCategory || order.category === selectedCategory)
        )
        .map((order) => [
          order.createdBy.name,
          order.orderId,
          order.category,
          order.size,
          order.weight,
          order.statusIs,
          order.workshop,
          order.kariger,
          new Date(order.createdAt).toLocaleString(),
        ]);
    } else {
      data = orders.map((order) => [
        order.createdBy.name,
        order.orderId,
        order.category,
        order.size,
        order.weight,
        order.statusIs,
        order.workshop,
        order.kariger,
        new Date(order.createdAt).toLocaleString(),
      ]);
    }

    // Insert the headers at the beginning of the data array
    data.unshift(headers);

    // Create a new workbook and sheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Add the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Order List");

    // Convert the workbook to a binary XLSX file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convert the binary XLSX to a Blob
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    // Save the Blob as a file using FileSaver.js
    saveAs(
      blob,
      `${filename}${
        Object.keys(filterNames)
          .map((key) => filterNames[key])
          .filter(Boolean).length > 0
          ? "_"
          : ""
      }${Object.keys(filterNames)
        .map((key) => filterNames[key])
        .filter(Boolean)
        .join("_")}.xlsx`
    );
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      <div className="container mt-5">
        <ToastContainer />
        <div className="pt-3">
          <h3 className="text-dark text-center mt-4">Review Orders</h3>
          <hr />
        </div>

        <span
          className="icon-end outlined-button  ms-auto"
          onClick={handlecanvaShow}
          style={{ width: "70px" }}
        >
          Filter<span class="material-symbols-outlined ps-1">tune</span>
        </span>
        <Button
          variant="normal"
          onClick={handleOrderList}
          style={{ backgroundColor: "rgb(255, 218, 185)", color: "brown" }}
        >
          Order List
        </Button>
        <Offcanvas show={canvashow} placement="end" onHide={handlecanvaClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter</Offcanvas.Title>
            <span className="icon-end cursor-pointer" onClick={resetAll}>
              <b style={{ fontSize: 20 }}>reset all</b>
              <span class="material-symbols-outlined">device_reset</span>
            </span>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {" "}
            <h6>
              Select Category{" "}
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">All Categories</option>
                <option value="ring">Ring</option>
                <option value="earring">Earring</option>
                <option value="bracelet">Bracelet</option>
                <option value="chain">Chain</option>
                <option value="nosepin">Nosepin</option>
                <option value="necklace">Necklace</option>
                <option value="mangalsutra">Mangalsutra</option>
                <option value="pendants">Pendants</option>
                <option value="others">Others</option>
              </select>
            </h6>
            <br />
            <h6>
              <input
                type="checkbox"
                checked={isUrgent}
                onChange={(e) => setIsUrgent(e.target.checked)}
              />{" "}
              Urgent Orders Only
            </h6>
            <br />
            {/* <h6>Search by Jeweller`s Name <input type="text" value={selectedOrderID} onChange={(e) => setSelectedOrderID(e.target.value)} placeholder="Enter Jeweller`s Name" /></h6> */}
            {/* <h6>Search by Jeweller's Name</h6>
<select value={selectedOrderID} onChange={(e) => setSelectedOrderID(e.target.value)}>
 {orders && orders.map((item, index) => (
    <option key={index} value={item.createdBy.name}>{item.createdBy.name}</option>
  ))}
</select> */}
            <h6>Search by Jeweller's Name</h6>
            <select
              value={selectedOrderID}
              onChange={(e) => setSelectedOrderID(e.target.value)}
            >
              <option value="">Select Jeweller's Name</option>
              {orders &&
                getUniqueNames(orders).map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
            </select>
          </Offcanvas.Body>
        </Offcanvas>

        <div className="row ms-auto me-auto">
          {/* {orders && orders.reverse().map((items, index) => { */}
          {orders &&
            orders
              .filter(
                (order) =>
                  (!isUrgent || order.urgent === isUrgent) &&
                  (!selectedOrderID ||
                    order.createdBy.name.toLowerCase() ===
                      selectedOrderID.toLowerCase()) &&
                  (!selectedCategory || order.category === selectedCategory)
              )
              .reverse()
              .map((items, index) => {
                return (
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <Card className="m-2" style={{ border: "1px outset" }}>
                      <div className="row d-flex ">
                        <div className="col-sm-10"></div>

                        <div className="col-sm-1" style={{ height: "10px" }}>
                          {items.statusIs == "completed" ? (
                            <span
                              className="material-symbols-outlined icon-end"
                              style={{ color: "green" }}
                            >
                              {/* hourglass_bottom */}
                              {/* stars */}
                              done
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-1" style={{ height: "10px" }}>
                          {items.urgent === true ? (
                            <span
                              className="material-symbols-outlined icon-end"
                              style={{ color: "#d42202" }}
                            >
                              {/* hourglass_bottom */}
                              {/* stars */}
                              notification_important
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {/* <Card.Img variant="top" className='p-2 ms-auto me-auto' style={{ width: '14rem', height: '14rem' }} src={items.image[0]} />  */}
                      <Carousel>
                        {items.image.map((img, index) => (
                          <Carousel.Item key={index}>
                            <Card.Img
                              variant="top"
                              className="d-block p-2 ms-auto me-auto"
                              style={{ width: "14rem", height: "14rem" }}
                              src={img}
                              alt="image"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <Card.Body>
                        <Card.Title>
                          <h6>OrderBy: {items.createdBy.name}</h6>
                        </Card.Title>
                        <Card.Title>
                          <h6>OrderId: {items.orderId}</h6>
                        </Card.Title>
                      </Card.Body>
                      <ListGroup className="list-group-flush">
                        <ListGroup.Item>
                          Category: {items.category}
                        </ListGroup.Item>
                        <ListGroup.Item>Size: {items.size}</ListGroup.Item>
                        <ListGroup.Item>Weight: {items.weight}</ListGroup.Item>
                        <ListGroup.Item>
                          Quantity: {items.quantity}
                        </ListGroup.Item>
                        <ListGroup.Item>Tunch: {items.tunch}</ListGroup.Item>
                        <ListGroup.Item>
                          Status: {items.statusIs}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Workshop: {items.workshop}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Kariger: {items.kariger}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Ordered On:{" "}
                          {new Date(items.createdAt).toLocaleString()}
                        </ListGroup.Item>
                        <ListGroup.Item
                          style={{
                            overflowX: "auto",
                            maxWidth: "100%",
                            display: "block",
                          }}
                        >
                          Audio Description:{" "}
                          {items.audio && items.audio.length > 0 ? (
                            <audio controls>
                              <source src={items.audio} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                height: "76px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No audio available.
                            </div>
                          )}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Body>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            updateItem(items._id);
                          }}
                        >
                          <AiFillEdit />
                          Update
                        </Button>
                        <Button
                          className="btn btn-danger ms-2"
                          onClick={() => {
                            delItem(items._id);
                          }}
                        >
                          <AiFillDelete />
                          Delete
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
        </div>

        {editOrder &&
          editOrder.map((items, index) => {
            return (
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="scrollable">
                  {/* <img variant="top" src={items.image[0]} className='img-thumbnail ' /> */}
                  <Carousel>
                    {items.image.map((img, index) => (
                      <Carousel.Item key={index}>
                        <Card.Img
                          variant="top"
                          className="d-block p-2 ms-auto me-auto"
                          style={{ width: "14rem", height: "14rem" }}
                          src={img}
                          alt="image"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <h6>OrderId: {items.orderId}</h6>
                  <Form
                    action=""
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      UpdateDetails(items._id);
                    }}
                  >
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Description:</Accordion.Header>
                        <Accordion.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              Ordered On:{" "}
                              {new Date(items.createdAt).toLocaleString()}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              OrderId: {items.orderId}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Size:
                              <Form.Control
                                type="text"
                                id="size"
                                name="size"
                                onChange={inputHandler}
                                value={updateOrder.size}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Weight:
                              <Form.Control
                                type="text"
                                id="weight"
                                name="weight"
                                onChange={inputHandler}
                                value={updateOrder.weight}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Quantity:
                              <Form.Control
                                type="text"
                                id="quantity"
                                name="quantity"
                                onChange={inputHandler}
                                value={updateOrder.quantity}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Tunch:
                              <Form.Control
                                type="text"
                                id="tunch"
                                name="tunch"
                                onChange={inputHandler}
                                value={updateOrder.tunch}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Status: {items.statusIs}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Assigned To Workshop: {items.workshop}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Assigned To Kariger: {items.kariger}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Description: 
                              <Form.Control
                                type="text"
                                id="description"
                                name="description"
                                onChange={inputHandler}
                                value={updateOrder.description}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <Button
                                variant="secondary"
                                className="text-center ms-auto me-auto mt-2"
                                type="submit"
                              >
                                Save Details
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      UpdateStatus(items._id);
                    }}
                  >
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Update Status "
                    >
                      <Form.Select
                        name="status"
                        onChange={(e) => setCurrentStatus(e.target.value)}
                      >
                        <option disabled selected>
                          Update Status
                        </option>
                        <option id="status" value="pending">
                          Pending
                        </option>
                        <option id="status" value="processing">
                          Processing
                        </option>
                        <option id="status" value="completed">
                          Completed
                        </option>
                        <option id="status" value="rejected">
                          Reject
                        </option>
                      </Form.Select>
                      <Button
                        variant="secondary"
                        className="text-center ms-auto me-auto mt-2"
                        type="submit"
                      >
                        Save Status
                      </Button>
                    </FloatingLabel>
                  </Form>
                  <Form onSubmit={saveUpdate}>
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Task Assign "
                    >
                      <Form.Select
                        htmlFor="order"
                        name="order"
                        onChange={inputHandler}
                      >
                        <option>Select OrderId</option>

                        <option id="order" value={items._id}>
                          {items.orderId}
                        </option>
                      </Form.Select>
                      <br />
                      <Form.Select
                        htmlFor="workshop"
                        name="workshop"
                        onChange={inputHandler}
                      >
                        <option>Select Workshop</option>
                        {workshop &&
                          workshop.map((items, index) => (
                            <option id="workshop" key={index} value={items._id}>
                              {items.name}{" "}
                            </option>
                          ))}
                      </Form.Select>
                      <br />
                      <Form.Select
                        htmlFor="kariger"
                        name="kariger"
                        onChange={inputHandler}
                      >
                        <option>Select Kariger</option>
                        {kariger &&
                          kariger
                            .filter(
                              (kariger) =>
                                kariger.workshop._id === task.workshop
                            )
                            .map((items, index) => (
                              <option
                                id="kariger"
                                key={index}
                                value={items._id}
                              >
                                {items.name}
                              </option>
                            ))}
                      </Form.Select>

                      <Button
                        variant="secondary"
                        className="text-center ms-auto me-auto mt-2"
                        type="submit"
                      >
                        Assign
                      </Button>
                    </FloatingLabel>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  {/* <Button variant="primary" type='submit'  onClick={handleClose}>
                  Save Changes
                </Button> */}
                </Modal.Footer>
              </Modal>
            );
          })}
      </div>
    </>
  );
};

export default ReviewOrders;
