import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const K_OrderList = () => {
  const { id } = useParams();
  console.log("id", id);
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [order, setOrder] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const removeItem = async (oid) => {
    if (window.confirm("Do you want to remove this?")) {
      try {
        const res = await axios.delete(
          `https://admin.ngjeweller.in:8000/kariger/${id}/order/${oid}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.success) {
          toast.warn("Order deleted...");
          // Update the list state by filtering out the deleted order
          const updatedList = list.filter((order) => order._id !== oid);
          setList(updatedList);
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        toast.error("Failed to delete order. Please try again later.");
      }
    }
  };

  let token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`https://admin.ngjeweller.in:8000/kariger/alltask/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setList(res.data.data[0].task.reverse());
      });
  }, [list]);
  console.log("id", id);
  console.log("list", list);
  return (
    <>
      <div className="container mt-5 pt-5">
        <div className="pt-2">
          <h3 className="text-dark text-center mt-2">Order List</h3>
          <hr />
        </div>
        <div className="row">
          {list &&
            list &&
            list.map((items, index) => {
              console.log("items", items);
              return (
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <Card className="m-2" style={{ border: "1px outset" }}>
                    <Card.Img
                      variant="top"
                      className="p-2 ms-auto me-auto"
                      style={{ width: "14rem", height: "14rem" }}
                      src={items.image[0]}
                    />
                    <Card.Body>
                      <Card.Title>
                        <h6>OrderBy: {items.createdBy.name}</h6>
                      </Card.Title>
                      <Card.Title>
                        <h6>OrderId: {items.orderId}</h6>
                      </Card.Title>
                    </Card.Body>

                    <ListGroup className="list-group-flush">
                      <ListGroup.Item>
                        Category: {items.category}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Ordered On: {new Date(items.createdAt).toLocaleString()}
                      </ListGroup.Item>
                      <ListGroup.Item>OrderId: {items.orderId}</ListGroup.Item>
                      <ListGroup.Item>Size: {items.size}</ListGroup.Item>
                      <ListGroup.Item>Weight: {items.weight}</ListGroup.Item>
                      <ListGroup.Item>Status: {items.statusIs}</ListGroup.Item>
                      <ListGroup.Item>
                        Assigned To Workshop: {items.workshop}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Assigned To Kariger: {items.kariger}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Description: {items.description}
                      </ListGroup.Item>
                      <ListGroup.Item
                        style={{
                          overflowX: "auto",
                          maxWidth: "100%",
                          display: "block",
                        }}
                      >
                        Audio Description:{" "}
                        {items.audio && items.audio.length > 0 ? (
                          <audio controls>
                            <source src={items.audio} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              height: "76px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No audio available.
                          </div>
                        )}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="d-flex justify-content-center mb-2">
                          <Button
                            className="btn btn-danger"
                            onClick={() => {
                              removeItem(items._id);
                            }}
                          >
                            <AiFillDelete />
                            Remove
                          </Button>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default K_OrderList;
